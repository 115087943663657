// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import jQuery from 'jquery/dist/jquery';
window.jQuery = jQuery;
window.$ = jQuery;

import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import * as ActiveStorage from '@rails/activestorage';
import 'channels';
import './web/tiny_mce';
import 'cookieconsent/build/cookieconsent.min';
import './script';

import Cookies from './cookies';
import alertsScripts from './alerts';
import googleAds from './googleAds';

Rails.start();
Turbolinks.start();
ActiveStorage.start();

$(document).on('turbolinks:render', function () {
	// turbolinks fix
	tinymce.remove();
});

$(document).on('turbolinks:load', function () {
	Cookies.init();
	alertsScripts.init();
	googleAds.init();
	initializeTextEditor();
});

function initializeTextEditor() {
	// Initialize the app
	tinymce.init({
		selector: '.js-text-editor',
		mobile: {
			menubar: false,
		},
		menubar: '',
		plugins: ['paste'],
		toolbar: '',
		paste_webkit_styles: 'none',
		valid_elements: 'p',
		paste_data_images: false,
		paste_as_text: true,
		branding: false,
		relative_urls: false,
	});
}
