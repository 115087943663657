import jQuery from 'jquery/dist/jquery';
window.jQuery = jQuery;
window.$ = jQuery;

/**
 * @created by Zheko Hristov on 27/08/2021
 * @last-updated by Petar Dimitrov on 30/09/2022
 *
 * Alerts scripts
 **/

const alertsScripts = (function () {
	'use strict';

	/**
	 * @created by Zheko Hristov on 27/08/2021
	 * @last-updated by Zheko Hristov on 30/08/2021
	 *
	 * Enable timout for hiding the alert containers function
	 **/
	let clerAlertContainersTimeOutFunc;
	function clearAlertsContainersAfterTime() {
		clerAlertContainersTimeOutFunc = setTimeout(function () {
			// hide alerts
			hideAlerts();
		}, 3000);
	}

	/**
	 * @created by Zheko Hristov on 30/08/2021
	 * @last-updated by Zheko Hristov on 30/08/2021
	 *
	 * Create single alert container and append it to element
	 * with class 'alerts__container--outer' function
	 **/
	function createSingleAlertAndAppendIt(messageToAppend, typeToAppend) {
		const alertContainerTemplate = `<div class="alerts__container--inner ${typeToAppend}">
                <div class="alerts__container--close">
                    <i class="fa-solid fa-xmark close-icon"></i>
                </div>
                <div class="alerts__container--message">
                    <i class="fa-solid fa-circle-check"></i>
                    <i class="fa-solid fa-circle-exclamation"></i>
                    <i class="fa-solid fa-circle-info"></i>
                    <i class="fa-solid fa-exclamation"></i>
                    ${messageToAppend}
                </div>
            </div>`;

		document
			.querySelector('.alerts__container--outer')
			.insertAdjacentHTML('afterbegin', alertContainerTemplate);
	}

	/**
	 * @created by Zheko Hristov on 30/08/2021
	 * @last-updated by Zheko Hristov on 30/08/2021
	 *
	 * Show alerts with some transition and delay function
	 **/
	function showAlerts() {
		const alertsContainers = document.querySelectorAll('.alerts__container--inner');
		const alertsContainersLength = alertsContainers.length;
		for (let i = 0; i < alertsContainersLength; i++) {
			setTimeout(function () {
				alertsContainers[i].classList.add('showed');
			}, 250 * i);
		}
	}

	/**
	 * @created by Zheko Hristov on 27/08/2021
	 * @last-updated by Zheko Hristov on 30/08/2021
	 *
	 * Hide alerts with some transition and delay function
	 **/
	function hideAlerts() {
		const alertsContainerOuter = document.querySelector('.alerts__container--outer');
		const alertsContainers = document.querySelectorAll('.alerts__container--inner');
		const alertsContainersLength = alertsContainers.length;

		for (let i = 0; i < alertsContainersLength; i++) {
			alertsContainers[i].style.pointerEvents = 'none';
			setTimeout(function () {
				alertsContainers[i].classList.remove('showed');
			}, 250 * i);
		}

		setTimeout(function () {
			for (let i = 0; i < alertsContainersLength; i++) {
				if (alertsContainerOuter.children.length) {
					alertsContainerOuter.removeChild(alertsContainers[i]);
				}
			}
		}, 250 * alertsContainersLength);
	}

	/**
	 * @created by Zheko Hristov on 27/08/2021
	 * @last-updated by Zheko Hristov on 30/08/2021
	 *
	 * Init function
	 **/
	function init() {
		// clear timeout, because when change pages the timeout continue runing
		clearTimeout(clerAlertContainersTimeOutFunc);
		// show alerts
		showAlerts();
		// hide the alerts after some time
		clearAlertsContainersAfterTime();
		// hide specified alert container by click on X icon
		document.querySelector('.alerts__container--outer').addEventListener('click', function (e) {
			if (e.target.classList.contains('close-icon')) {
				let alertInnerContainer = e.target.parentNode.parentNode;
				alertInnerContainer.classList.remove('showed');
				setTimeout(function () {
					alertInnerContainer.parentNode.removeChild(alertInnerContainer);
				}, 250);
			}
		});
	}

	/**
	 * @created by Zheko Hristov on 27/08/2021
	 * @last-updated by Zheko Hristov on 30/08/2021
	 *
	 * Render single alert function
	 * usage example: Alerts.renderAlert('test message success', 'success');
	 **/
	function renderAlert(message, type) {
		// hide alerts
		hideAlerts();
		const showedAlertsContainersLength = document.querySelectorAll(
			'.alerts__container--inner'
		).length;
		setTimeout(function () {
			clearTimeout(clerAlertContainersTimeOutFunc);
			// create single alert and append it
			createSingleAlertAndAppendIt(message, type);
			setTimeout(function () {
				// show alert
				document.querySelector('.alerts__container--inner').classList.add('showed');
				// hide the alerts after some time
				clearAlertsContainersAfterTime();
			}, 250);
		}, 250 * showedAlertsContainersLength);
	}

	/**
	 * @created by Zheko Hristov on 30/08/2021
	 * @last-updated by Zheko Hristov on 30/08/2021
	 *
	 * Render multiple alerts function
	 * usage example:
	 * Alerts.renderAlerts(['test message 1 error', 'test message 2 error'], 'error');
	 **/
	function renderAlerts(alertsArr, type) {
		// hide alerts
		hideAlerts();
		const showedAlertsContainersLength = document.querySelectorAll(
			'.alerts__container--inner'
		).length;
		setTimeout(function () {
			clearTimeout(clerAlertContainersTimeOutFunc);
			for (let i = 0; i < alertsArr.length; i++) {
				// create single alert and append it
				createSingleAlertAndAppendIt(alertsArr[i], type);
			}
			setTimeout(function () {
				// show alerts
				showAlerts();
				// hide the alerts after some time
				clearAlertsContainersAfterTime();
			}, 250);
		}, 250 * showedAlertsContainersLength);
	}

	/**
	 * @created by Zheko Hristov on 27/08/2021
	 * @last-updated by Zheko Hristov on 27/08/2021
	 *
	 * Clear alert containers function for browser back button issue
	 **/
	function clearAlertsBrowserBack() {
		while (document.querySelector('.alerts__container--outer').lastChild) {
			document
				.querySelector('.alerts__container--outer')
				.removeChild(document.querySelector('.alerts__container--outer').lastChild);
		}
	}

	return {
		init: init,
		renderAlert: renderAlert,
		renderAlerts: renderAlerts,
		clearAlertsBrowserBack: clearAlertsBrowserBack,
	};
})();

export default alertsScripts;
