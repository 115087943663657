/**
 * @created by Petar Dimitrov on 03/10/2022
 * @last-updated by Petar Dimitrov on 04/10/2022
 *
 * Google AD script
 **/

var googleAds = (function ($) {
	'use strict';

	function init() {
		dynamicAdsenseInsertion();
	}

	// Dynamic Adsense insertion
	function dynamicAdsenseInsertion() {
		$('.ad__container').each(function () {
			$(this).append(`<ins class="adsbygoogle"
            style="display:inline-block;width:100%;height:100px"
            data-ad-client="ca-pub-7151034579215698"
            data-ad-slot="1658274484"></ins>`);
			(adsbygoogle = window.adsbygoogle || []).push({});
		});
	}

	return {
		init: init,
	};
})(jQuery);

export default googleAds;
