/**
 * @created by Petar Dimitrov on 23/08/2022
 * @last-updated by Petar Dimitrov on 04/10/2022
 *
 * Site scripts
 **/

$(document).on('turbolinks:before-render', function () {
	'use strict';

	// Close mobile menu if it's not closed when clicking the browser back button and remove body scroll
	if ($('.mobile-menu__container').hasClass('open')) {
		$('.mobile-menu__container').removeClass('open');
		$('body').removeClass('disabled-scroll');
	}
});

$(document).on('turbolinks:load', function () {
	'use strict';

	// Check if device is running iOS
	function checkIfIsIOS() {
		return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
	}

	// Sets maximum-scale=1.0 to meta viewport, prevents input zoom only on iOS
	function addMaximumScaleToMetaViewport() {
		const el = document.querySelector('meta[name=viewport]');
		if (el !== null) {
			let content = el.getAttribute('content');
			let re = /maximum\-scale=[0-9\.]+/g;
			if (re.test(content)) {
				content = content.replace(re, 'maximum-scale=1.0');
			} else {
				content = [content, 'maximum-scale=1.0'].join(', ');
			}
			el.setAttribute('content', content);
		}
	}

	if (checkIfIsIOS()) {
		addMaximumScaleToMetaViewport();
	}

	// Get main__container--outer's padding-top value
	let mainContainerPaddingTop = parseFloat($('.main__container--outer').css('padding-top'));

	// Get scroll position and style the header
	function topMenu() {
		let scrollPos = $(document).scrollTop();
		if (window.innerWidth > 1080) {
			if (scrollPos >= mainContainerPaddingTop) {
				$('header').addClass('not-on-top');
			} else {
				$('header').removeClass('not-on-top');
			}
		} else {
			$('header').addClass('not-on-top');
		}
	}
	topMenu();

	// Calculate .main__container--outer min-height
	function calculateMainContainerOuterMinHeight() {
		let windowHeight = $(window).outerHeight();
		let footerOuterHeight = $('footer').outerHeight();
		let calculatedMainContainerMinHeightValue = windowHeight - footerOuterHeight;
		$('.main__container--outer').css({
			'min-height': calculatedMainContainerMinHeightValue,
		});
	}
	calculateMainContainerOuterMinHeight();

	$(window).on('scroll', function () {
		topMenu();
	});

	$(window).on('resize', function () {
		topMenu();
		calculateMainContainerOuterMinHeight();
	});
	$(window).trigger('resize');

	// Sets input[type='date]'s max value to today's date;
	function validateDate() {
		$('.js-input-type-date').prop('max', function () {
			return new Date().toJSON().split('T')[0];
		});
	}
	// Attaching the click event to the input
	$('.js-input-type-date').on('click', validateDate);

	//Check if comment textarea is empty and add/remove disabled class from submit button
	function checkComment() {
		let commentBtn = $('.js-comment-button');
		if ($.trim($('.js-comment').val())) {
			$(commentBtn).removeClass('disabled').prop('disabled', false);
		} else {
			$(commentBtn).addClass('disabled').prop('disabled', true);
		}
	}
	// Attaching the click event to the comment button
	$('.js-comment').on('input', function () {
		setTimeout(function () {
			checkComment();
		}, 250);
	});

	// Add main navigation links to mobile menu
	function getNavigationLinks() {
		let mainPages = $('.header__container--links nav').html();
		let profileLinks = $('.header__container--account').html();

		if (!$('.mobile-menu--inner nav').children().length > 0) {
			$('.mobile-menu--top').prepend(profileLinks);
			$('.mobile-menu--inner nav').append(mainPages);
		}
	}

	// Disable body scrollmobile-menu__container
	function disableBodyScroll() {
		if ($('body').hasClass('disabled-scroll')) {
			$('body').removeClass('disabled-scroll');
		} else {
			$('body').addClass('disabled-scroll');
		}
	}

	// Open or close the mobile menu
	function openCloseMobileMenu() {
		let mobileMenu = $('.mobile-menu__container');

		if ($(mobileMenu).hasClass('open')) {
			$(mobileMenu).removeClass('open');
		} else {
			$(mobileMenu).addClass('open');
		}
	}

	// Parent function for mobile menu
	function toggleMobileMenu() {
		getNavigationLinks();
		openCloseMobileMenu();
		disableBodyScroll();
	}
	// Attaching the click event to the mobile menu open/close button
	$('.js-open-mobile-menu').on('click', toggleMobileMenu);
	$('.js-close-mobile-menu').on('click', toggleMobileMenu);
});
