/**
 * @created by Plamena Gancheva on 08/11/2021
 * @last-updated by Petar Dimitrov on 03/19/2022
 *
 * Cookies scripts
 **/

var Cookies = (function ($) {
	function init() {
		askCookiesPermission();
	}

	function askCookiesPermission() {
		window.cookieconsent.initialise({
			palette: {
				popup: {
					background: '#062319',
					text: '#fff',
				},
				button: {
					background: '#2e4b3f',
					text: '#fff',
				},
			},
			theme: 'classic',
			content: {
				message:
					'Този сайт използва „Бисквитки“. Продължавайки да използвате сайта, Вие се съгласявате с тяхното използване. За повече информация, прочетете нашата',
				link: 'Политика за бисквитки',
				href: 'https://pravnisaveti.com/cookies',
				dismiss: 'Разбрах!',
			},
		});
	}

	return {
		init: init,
	};
})(jQuery);

export default Cookies;
